import React, { Fragment } from "react"
import { graphql } from "gatsby"
import Helmet from "../components/Helmet"
import Header from "../components/Header"
import One from "../recapPage/One"
import Two from "../recapPage/Two"
import Footer from "../components/Footer"

const RecapPage = ({ data }) => (
  <Fragment>
    <Helmet
      title="Stock Analysis  - Weekly Stock Market Recap - ETF Stock Report"
      descr="Learn more about the top issues affecting stocks and the economy with actionable investment advice and insightful stock market research and analysis from the ETF Stock Report."
      slug="/recap"
    />
    <Header />
    <One />
    <Two data={data} />
    <Footer />
  </Fragment>
)

export default RecapPage

export const pageQuery = graphql`
  query {
    allWpPost(
      sort: { fields: [date], order: DESC }
      filter: {
        categories: { nodes: { elemMatch: { name: { eq: "Weekly Recaps" } } } }
      }
    ) {
      nodes {
        id
        title
        date
        slug
        content
      }
    }
  }
`
