import React, { useState, useEffect, useRef, Fragment } from "react"
import Select from "../../components/Select"
import Msg from "../../components/Msg"
import Submit from "../../components/Submit"
import * as styles from "./styles.module.css"
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons"

const Two = ({ data }) => {
  const [names, setNames] = useState({
    id: "",
  })
  const [errors, setErrors] = useState([])
  const [msg, setMsg] = useState({})

  const formElement = useRef(null)

  useEffect(() => {
    setNames({
      id: data.allWpPost.nodes[0].id,
      title: data.allWpPost.nodes[0].title,
      content: data.allWpPost.nodes[0].content,
    })
  }, [data])

  const handleUpdate = (name, value) => {
    setNames(names => ({ ...names, [name]: value ? value : "" }))
    if (name === "date" && value) {
      handleChange(value)
    }
  }

  const handleChange = async index => {
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })
    setNames({
      id: data.allWpPost.nodes[index].id,
      title: data.allWpPost.nodes[index].title,
      content: data.allWpPost.nodes[index].content,
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()
  }

  const handleResponse = (json, error) => {
    if (json) {
      if (json.resp === 1) {
        setMsg({})
        //setData({ ...json })
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } else {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <section className={styles.one}>
      <div className={styles.grid}>
        <div>
          <form
            method="post"
            action="/"
            onSubmit={e => handleSubmit(e)}
            ref={formElement}
            style={{
              width: "50%",
              marginTop: "0",
              paddingTop: "0",
              marginBottom: "15px",
            }}
          >
            <div className="one">
              <Select
                name="date"
                label="Archive Date"
                reqd={true}
                autocomplete="off"
                options={[].concat(
                  data.allWpPost.nodes.map((node, index) => {
                    return {
                      value: index,
                      name: node.title,
                    }
                  })
                )}
                update={handleUpdate}
                errors={errors}
                value={""}
              />

              <div className="empty" />
            </div>
          </form>

          <p className={styles.date}>
            <strong>{names.title}</strong>
          </p>

          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: names.content }}
          />
        </div>
      </div>
    </section>
  )
}

export default Two
